<template>
  <div class="bg-white creative relative">
    <el-tabs
      type="card"
      editable
      v-model="current"
      :before-leave="beforeLeave"
      @edit="handleTabsEdit"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(creative, index) in value"
        :key="index"
        :name="index + ''"
      >
        <div slot="label">
          <i
            class="el-icon-warning text-red-600"
            v-if="errList.includes(index)"
          ></i>
          {{ creativeName(creative, index) }}
        </div>
        <div class="m-6">
          <el-form
            ref="form"
            label-width="100px"
            :model="creative"
            :rules="getRules(creative)"
          >
            <el-form-item label="广告类型">
              <el-tabs
                type="border-card"
                v-model="creative.type"
                @tab-click="(tab) => selectCreativeType(tab, index)"
              >
                <el-tab-pane
                  label="图片"
                  name="DEMAND_GEN_MULTI_ASSET_AD"
                >
                </el-tab-pane>
                <el-tab-pane
                  label="视频"
                  name="DEMAND_GEN_VIDEO_RESPONSIVE_AD"
                >
                </el-tab-pane>
                <div>
                  <!-- 图片 -->
                  <el-form-item
                    class="flex"
                    prop="multiImages"
                    v-show="!isVideo(creative)"
                  >
                    <UploadImage
                      :value.sync="creative.multiImages"
                      :current-account="currentAccount"
                    />
                  </el-form-item>
                  <!-- 视频 -->
                  <el-form-item
                    class="flex"
                    prop="videoAssets"
                    v-show="isVideo(creative)"
                  >
                    <UploadVideo
                      v-model="creative.videoAssets"
                      :current-account="currentAccount"
                      @input="(val) => getCreativeValue(val, index, 'videoAssets')"
                    />
                  </el-form-item>
                </div>
              </el-tabs>
            </el-form-item>
            <el-form-item
              label="徽标"
              prop="logoImages"
            >
              <UploadLogo
                v-model="creative.logoImages"
                :current-account="currentAccount"
                :type="creative.type"
              />
            </el-form-item>
            <el-form-item
              label="标题"
              prop="headlines"
            >
              <CreativeTitle
                v-model="creative.headlines"
                :maxlength="40"
                type="标题"
              />
            </el-form-item>
            <el-form-item
              label="长标题"
              prop="videoLongHeadlines"
              v-show="isVideo(creative)"
            >
              <CreativeTitle
                type="长标题"
                v-model="creative.videoLongHeadlines"
                :maxlength="90"
                @input="(val) => getCreativeValue(val, index, 'longHeadlines')"
              />
            </el-form-item>
            <el-form-item
              label="描述"
              prop="descriptions"
            >
              <CreativeTitle
                type="描述"
                v-model="creative.descriptions"
                :maxlength="90"
              />
            </el-form-item>
            <el-form-item
              label="行动号召"
              prop="videoCallToActions"
              v-show="isVideo(creative)"
            >
              <CallToAction
                type="video"
                v-model="creative.videoCallToActions"
                :enum-constants="enumConstants"
                @input="(val) => getCreativeValue(val, index, 'videoCallToActions')"
              />
            </el-form-item>
            <el-form-item
              label="行动号召"
              prop="callToAction"
              v-show="!isVideo(creative)"
            >
              <CallToAction
                type="img"
                v-model="creative.callToAction"
                :enum-constants="enumConstants"
                @input="(val) => getCreativeValue(val, index, 'callToAction')"
              />
            </el-form-item>
            <el-form-item
              label="商家名称"
              prop="businessName"
            >
              <el-input
                v-model="creative.businessName"
                disabled
                class="flex-1"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane name="add">
        <span
          slot="label"
          @click.stop="$emit('addCreative')"
          ><i class="el-icon-circle-plus-outline"></i> 添加创意</span
        >
      </el-tab-pane>
    </el-tabs>
    <div class="absolute z-50 right-6 top-0 h-10 leading-10">
      <div
        slot="label"
        class="hover:text-primary text-sm leading-10"
        @click.stop="copyItem"
      >
        <i class="el-icon-copy-document mr-1" />复制创意
      </div>
    </div>
    <!-- 图片素材库 -->
    <ImageLibrary />
  </div>
</template>
<script>
import CreativeTitle from '@/views/google/create/components/creatives/CreativeTitle/index.vue';
import CallToAction from '@/views/google/create/components/creatives/CallToAction/index.vue';
import UploadImage from '@/views/google/create/components/creatives/UploadImage/index.vue';
import UploadLogo from '@/views/google/create/components/creatives/UploadLogo/index.vue';
import ImageLibrary from '@/views/google/create/components/creatives/ImageLibrary/index.vue';
import UploadVideo from '@/views/google/create/components/creatives/UploadVideo/index.vue';
import { callToActionList, languages, imgRules, videoRules } from './index';
import { getVideoCreative, getImgCreative, extractDomain } from '../../index';
export default {
  components: { CreativeTitle, CallToAction, UploadImage, UploadLogo, ImageLibrary, UploadVideo },
  props: {
    // 广告数组
    value: {
      type: Array,
      default: () => [],
    },
    // 整体的数据
    data: {
      type: Object,
      default: () => {},
    },
    // 基础数据
    enumConstants: {
      type: Object,
      default: () => {},
    },
    // 账户信息
    currentAccount: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      callToActionList,
      languages,
      imgRules,
      videoRules,
      current: '0', //当前创意
      errList: [], //不通过的创意索引列表
    };
  },
  computed: {},
  methods: {
    /**
     * 获取验证规则
     * @param  creative 当前创意
     */
    getRules(creative) {
      return this.isVideo(creative) ? this.videoRules : this.imgRules;
    },
    /**
     * 切换选项卡
     */
    beforeLeave(active, oldActive) {
      if (active === 'add') {
        this.$emit('addCreative');
        return false;
      }
    },
    /**
     * 当前创意是不是视频广告
     */
    isVideo(creative) {
      return creative.type === 'DEMAND_GEN_VIDEO_RESPONSIVE_AD';
    },
    /**
     * 复制创意
     */
    copyItem() {
      let current = this.value[this.current];
      console.log(current);
      this.value.push(JSON.parse(JSON.stringify(current)));
      this.$emit('input', this.value);
    },

    /**
     * 对外提供的验证
     */
    validate() {
      let list = [];
      this.errList = [];
      this.$refs.form.map((form, index) => {
        list.push(form.validate().catch(() => index));
      });
      return new Promise((resolve, reject) => {
        Promise.allSettled(list).then((res) => {
          res.map((item) => {
            if (typeof item.value === 'number') {
              this.errList.push(item.value);
              reject();
            }
          });
          resolve();
        });
      });
    },
    /**
     * 清除验证
     */
    clearValidate() {
      if (!this.$refs.form) return;
      let list = [];
      this.errList = [];
      this.$refs.form.map((form) => {
        list.push(form.clearValidate());
      });
      return Promise.all(list);
    },
    /**
     * 改变创意的值
     * @param {*} value  改变后的值
     * @param {*} index  当前创意的index
     * @param {*} key    改变的属性
     */
    getCreativeValue(value, index, key) {
      this.value[index][key] = value;
      this.$forceUpdate();
    },
    /**
     * 获取当前创意的名称
     * @param {*} creative 当前创意
     * @param {*} index     当前创意索引
     */
    creativeName(creative, index) {
      let name = this.isVideo(creative) ? '视频' : '图片';
      console.log(name);
      return name + ' ' + (index + 1);
    },
    /**
     * 点击创意选项卡
     * @param {*} tab
     */
    handleClick(tab) {
      //   添加创意
      if (tab.name === '-1') {
        this.current = this.current;
        return false;
      }
    },
    /**
     * 编辑选项卡
     * @param {*} index
     */
    handleTabsEdit(index) {
      if (this.value.length === 1) {
        this.$message({
          type: 'warning',
          message: '至少保留一个创意',
        });
        return;
      }
      this.value.splice(index, 1);
      if (this.current >= this.value.length) {
        this.current = `${this.value.length - 1}`;
      }
      this.$emit('input', this.value);
    },
    /**
     * 切换广告样式
     * @param {*tab} 点击tab
     * @param {*index}  当前创意索引
     */
    selectCreativeType(tab, index) {
      let creative =
        tab.name === 'DEMAND_GEN_VIDEO_RESPONSIVE_AD' ? { ...getVideoCreative() } : { ...getImgCreative() };
      creative.businessName = extractDomain(this.data.promoteInfo.promotionLink);
      this.$set(this.value, index, creative);
      this.$emit('input', this.value);
      this.$nextTick(() => {
        // 清除表单验证
        this.clearValidate();
      });
    },
  },
};
</script>

<style lang="scss">
.creative .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child span.el-icon-close {
  display: none !important;
}
.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item div {
  display: inline-block;
}
.creative .el-tabs__new-tab {
  display: none;
}
.el-upload--picture-card {
  width: 100px;
  height: 100px;
}
</style>
