const callToAction = require('../../json/callToAction.json');
const languageJson = require('../../json/language.json');
export const languages = Object.keys(languageJson).map((key) => {
  return { key, value: languageJson[key] };
});
export const callToActions = callToAction;

/**
 * 校验函数
 */
// 校验图片素材
export const validatorMedia = (rule, value, callback) => {
  if (!value || !value.length) {
    return callback(new Error('请上传图片素材'));
  }
  if (value.length > 20) {
    return callback(new Error('最多上传20张图片'));
  }
  const status = Array.from(new Set(value.map((item) => item.status)));
  if (!(status.length === 1 && status.includes('success'))) {
    return callback(new Error('存在上传中或上传失败的素材'));
  }
  callback();
};
// 校验视频素材
export const validatorVideo = (rule, value, callback) => {
  if (!value || !value.length) {
    return callback(new Error('请上传视频素材'));
  }
  if (value.length > 5) {
    return callback(new Error('最多上传5个视频'));
  }
  const status = Array.from(new Set(value.map((item) => item.status)));
  if (!(status.length === 1 && status.includes('succeeded'))) {
    return callback(new Error('存在上传中或上传失败的素材'));
  }
  callback();
};

// 校验徽标
export const validatorCollMedia = (rule, value, callback) => {
  if (!value || !value.length) {
    return callback(new Error('请上传徽标'));
  }
  if (value.length < 1 || value.length > 5) {
    return callback(new Error('请上传至少1张至多5张缩略图'));
  }
  callback();
};
const SpecialWords = ['*', '!', '㎡', 'F.L.O.W.E.R.S', '##'];

export const getByteLength = (str) => {
  if (!str) return 0;
  let byteLength = 0;
  // 使用 for...of 来确保正确遍历可能由代理对组成的字符
  for (const char of str) {
    const code = char.codePointAt(0);
    if (code <= 0x7f) {
      // ASCII 字符
      byteLength += 1;
    } else if (/\p{Emoji}/u.test(char)) {
      // Emoji 字符（需要环境支持 Unicode 属性转义）
      byteLength += 4;
    } else {
      // 其它非 ASCII 字符
      byteLength += 2;
    }
  }
  return byteLength;
};
// 校验标题
export const validateWord = (type, size, rule, value, callback) => {
  if (!value || !value.length || !value.filter((v) => v).length) {
    return callback(new Error(`请填写${type}`));
  }
  if (value.filter((v) => !v).length) {
    return callback(new Error(`${type}不能为空`));
  }
  if (value.map((v) => v).length > 5) {
    return callback(new Error(`最多填写5条${type}`));
  }
  if (new Set(value).size !== value.length) {
    return callback(new Error(`${type}不能重复`));
  }
  if (value.filter((v) => SpecialWords.some((item) => v && v.indexOf(item) > -1)).length) {
    return callback(new Error(`${type}包含特殊字符`));
  }
  if (value.filter((v) => getByteLength(v) > size).length) {
    return callback(new Error(`${type}长度超过${size}字节`));
  }
  callback();
};

// 图片视频通用校验
const baseRules = {
  callToAction: [
    {
      required: false,
      message: '请选择行动号召',
    },
  ],
  logoImages: {
    required: true,
    validator: validatorCollMedia,
  },
  headlines: [
    {
      required: true,
      validator: (rule, value, callback) => validateWord('标题', 40, rule, value, callback),
    },
  ],
  descriptions: [
    {
      required: true,
      validator: (rule, value, callback) => validateWord('描述', 90, rule, value, callback),
    },
  ],
  businessName: [
    {
      required: true,
      message: '商家名称必填',
    },
  ],
};

// 图片视频校验
export const imgRules = {
  ...baseRules,
  multiImages: [
    {
      validator: validatorMedia,
      required: true,
    },
  ],
};
// 图片视频校验
export const videoRules = {
  ...baseRules,
  videoLongHeadlines: [
    {
      required: true,
      validator: (rule, value, callback) => validateWord('视频长标题', 90, rule, value, callback),
    },
  ],
  videoAssets: [
    {
      validator: validatorVideo,
      required: true,
      trigger: 'change',
    },
  ],
};
