<template>
  <div class="bg-white p-5">
    <el-form
      label-position="top"
      size="medium"
    >
      <el-form-item label="请选择广告系列类型">
        <el-radio-group
          :value="type"
          @input="(val) => onChange(val, 'type')"
        >
          <el-radio-button
            label="DEMAND_GEN"
            key="DEMAND_GEN"
            >需求开发</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="请选择推广目标">
        <el-radio-group
          :value="optimizationGoal"
          @input="(val) => onChange(val, 'optimizationGoal')"
        >
          <el-radio-button
            label=""
            key=""
            >购买</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      type: 'DEMAND_GEN',
      optimizationGoal: '',
    };
  },
  methods: {
    onChange(val, key) {
      this.$emit(`update:${key}`, val);
    },
  },
};
</script>
